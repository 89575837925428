<template class="background">
  <div id="not-authorized">
    <layout :back="true" />
    <div
      style="padding: 10% 5%"
      class="items-center justify-center text-center "
    >
      <img
        src="@/assets/apotalent/Isotipo white.png"
        alt="graphic-not-authorized"
        class="mb-4"
      />
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl ">
        {{ $t("not_found.title") }}
      </h1>
      <v-row justify="center">
        <v-col cols="12" md="8" sm="10">
          <p class="sm:mx-0 mx-4 sm:mb-8 mb-8">
             {{ keyValues['not_found_text'] }}
          </p>
        </v-col>
      </v-row>
      <v-btn color="secondary" rounded size="large" to="/">{{
        $t("not_found.button")
      }}</v-btn>
    </div>
  </div>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Layout
  },
  computed: {
    ...mapGetters({ keyValues: "core/keyValues" })
  }
};
</script>
<style scoped>
.background {
  background-color: white !important;
  height: 100%;
}
</style>
